<script>
export default {
  name: 'AuthLayout',
};
</script>

<template>
  <div class="page-content">
    <!-- Main content -->
    <div class="content-wrapper">
      <!-- Content area -->
      <div class="content d-flex justify-content-center align-items-center">
        <!-- Password recovery form -->
        <div class="card mb-0 col-8">
          <div class="card-body">
            <transition name="fade" mode="out-in">
              <router-view />
            </transition>
          </div>
        </div>
        <!-- /password recovery form -->
      </div>
      <!-- /content area -->
    </div>
  </div>
</template>
